export default class {
  constructor() {
    const targetClass = "-target";
    const actionClass = "-action";
    const readyClass = "-ready";

    const options = {
      rootMargin: "0px 0px -10%",
      // threshold: [0, 0.2, 0.4, 0.6, 0.8, 1]
      // threshold: [0.2, 0.8]
      // threshold: 0,
      threshold: [0, 0.05, 0.95, 1],
    };

    const callback = (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.intersectionRatio > 0) {
          entry.target.classList.add(actionClass);
        } else if (entry.intersectionRatio === 0) {
          // entry.target.classList.remove("-action"); // 画面外に出たらアニメーションをリセットする
        }
      });
    };

    const observer = new IntersectionObserver(callback, options);

    const observers = [...document.querySelectorAll("." + targetClass + ', [class^="sec-"], [class*=" sec-"], section.mv')];

    observers.forEach((el) => {
      el.classList.add(readyClass);
      observer.observe(el);
    });
  }
}
