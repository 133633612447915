import { lenis } from "./Parallax";

export default class {
  constructor() {
    // ▼ グローバルメニュー
    const gnavOpen = document.querySelectorAll("#gnavOpen a");
    const gnavClose = document.querySelectorAll("#gnavClose, .gnavBg");
    const gnavOpenArray = Array.prototype.slice.call(gnavOpen, 0);
    const gnavCloseArray = Array.prototype.slice.call(gnavClose, 0);

    gnavOpenArray.forEach((element) => {
      element.addEventListener(
        "click",
        function (e) {
          document.querySelector("body").classList.add("-gnavOpen");
          lenis.stop();
          e.preventDefault();
        },
        false
      );
    });

    gnavCloseArray.forEach((element) => {
      element.addEventListener(
        "click",
        function (e) {
          document.querySelector("body").classList.remove("-gnavOpen");
          lenis.start();
          e.preventDefault();
        },
        false
      );
    });
  }
}
