import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Lenis from "@studio-freight/lenis";

export const lenis = new Lenis({
  wheelMultiplier: 0.6,
});

lenis.on("scroll", (e) => {
  // console.log(e);
});

function raf(time) {
  lenis.raf(time);
  requestAnimationFrame(raf);
}

requestAnimationFrame(raf);

const mm = 767; // matchMedia(SP)

gsap.registerPlugin(ScrollTrigger);

// SmoothScroll
class SmoothScroll {
  constructor() {
    this._initSmoothScroll();
  }

  _initSmoothScroll() {
    const smoothScrollTrigger = document.querySelectorAll('a[href^="#"]');
    for (let i = 0; i < smoothScrollTrigger.length; i++) {
      smoothScrollTrigger[i].addEventListener("click", (e) => {
        e.preventDefault();
        let href = smoothScrollTrigger[i].getAttribute("href");
        let targetElement = document.getElementById(href.replace("#", ""));
        const rect = targetElement.getBoundingClientRect().top;
        const offset = window.pageYOffset;
        const gap = 0;
        const target = rect + offset - gap;
        window.scrollTo({
          top: target,
          behavior: "smooth",
        });
      });
    }
  }
}

document.addEventListener(
  "DOMContentLoaded",
  () => {
    new SmoothScroll();
  },
  false
);

export default class {
  constructor() {
    let element;

    // ▼ PAGE TOP
    gsap.from(".pageTop", {
      autoAlpha: 0,
      scrollTrigger: {
        trigger: "#wrapper",
        start: window.innerHeight + " top",
        toggleActions: "play none none reverse",
        // markers: true,
      },
    });
    // ▼ ヘッダーが黒背景に重なったら色反転用Class付与
    element = document.querySelectorAll("section.-black, .pageHeader");
    if (element) {
      for (let index = 0; index < element.length; index++) {
        const item = element[index];
        ScrollTrigger.create({
          trigger: item,
          start: "top-=40 top",
          end: "bottom-=40 top",
          toggleClass: { targets: ".l-header", className: "-white" },
          // markers: true,
        });
      }
    }
    // ▼ TOP MVをスクロールしたらフェードアウト
    element = document.querySelector("section.mv");
    if (element) {
      gsap.to(element, {
        autoAlpha: 0,
        scrollTrigger: {
          trigger: "section.mv",
          start: "30% top",
          // scrub: true,
          toggleActions: "play none none reverse",
          // markers: true,
        },
      });
    }
    // ▼ TOP SHOP NEWS & TOPICS背景フェード
    element = document.querySelector(".sec-topTopics .topTopics_bgW");
    if (element) {
      gsap.to(element, {
        autoAlpha: 0,
        scrollTrigger: {
          trigger: ".sec-topTopics",
          start: "-20% top",
          end: "40% top",
          toggleActions: "play none none reverse",
          scrub: true,
          // markers: true,
        },
      });
    }
    element = document.querySelector(".sec-topTopics");
    if (element) {
      ScrollTrigger.create({
        trigger: element,
        start: "-100 bottom",
        end: "bottom top",
        toggleClass: { targets: ".sec-topTopics", className: "gsap-topTopicsBG" },
        // markers: true,
      });
    }
    // gsap.utils.toArray("section").forEach((target) => {
    //   ScrollTrigger.create({
    //     trigger: target,
    //     start: "top bottom",
    //     end: "bottom top",
    //     toggleClass: { targets: target, className: "gsap-topTopicsBG" },
    //     markers: true,
    //   });
    // });
    gsap.matchMedia().add("(min-width:" + (mm + 1) + "px)", () => {
      // ▼ TOP SHOP NEWS & TOPICSタイトルフェード
      element = document.querySelector(".sec-topTopics .title-section");
      if (element) {
        gsap.from(element, {
          autoAlpha: 0,
          scrollTrigger: {
            trigger: ".sec-topTopics",
            start: "40% center",
            // end: "bottom bottom",
            toggleActions: "play none none reverse",
            // scrub: true,
            // markers: true,
          },
        });
        // ▼ TOP SHOP NEWS & TOPICSタイトル固定
        ScrollTrigger.create({
          pinnedContainer: ".sec-topTopics",
          trigger: element,
          pin: true,
          start: "top top",
          endTrigger: ".sec-topTopics + section",
          end: "top bottom",
          pinSpacing: false,
          // markers: true,
        });
      }
      // ▼ TOP SHOP NEWS & TOPICS カードフェードイン
      element = document.querySelector(".sec-topTopics .list-topTopics_item");
      if (element) {
        gsap.from(element, {
          autoAlpha: 0,
          y: "15%",
          duration: 1,
          scrollTrigger: {
            trigger: ".sec-topTopics",
            start: "30% center",
            toggleActions: "play none none reverse",
            // markers: true,
          },
          stagger: {
            from: "start",
            amount: 0.5,
          },
        });
      }
    });
    // ▼ TOP ABOUT画像パララックス
    element = document.querySelector(".topAbout_item");
    if (element) {
      gsap.utils.toArray(".topAbout_item").forEach((target) => {
        const dataY = target.getAttribute("data-y") || 1000;
        // console.log(dataY);
        gsap.fromTo(
          target,
          {
            y: dataY * 10,
          },
          {
            y: dataY * -2,
            scrollTrigger: {
              trigger: ".topAbout",
              start: "0% center",
              end: "300% center",
              scrub: true,
              toggleActions: "play none none reverse",
              // markers: true,
            },
          }
        );
      });
    }
    // ▼ TOP ABOUT画像トリミングパララックス
    element = document.querySelector(".topAbout_item img");
    if (element) {
      gsap.utils.toArray(".topAbout_item img").forEach((target) => {
        const imgH = target.clientHeight;
        const maskH = target.parentNode.clientHeight;
        // console.log({ imgH, maskH });
        gsap.fromTo(
          target,
          {
            y: 0,
          },
          {
            y: -(imgH - maskH),
            scrollTrigger: {
              trigger: ".topAbout",
              start: "0% center",
              end: "200% top",
              scrub: true,
              toggleActions: "play none none reverse",
              // markers: true,
            },
          }
        );
      });
    }
    // ▼ TOP ABOUT 固定表示
    element = document.querySelector(".sec-topAbout");
    if (element) {
      ScrollTrigger.create({
        pinnedContainer: element,
        trigger: element,
        pin: true,
        start: "16.667% top",
        // end: "200% top",
        invalidateOnRefresh: true,
        // markers: true,
      });
    }
    // ▼ TOP ABOUT 背景アニメーション開始
    element = document.querySelector(".topAbout_images");
    if (element) {
      ScrollTrigger.create({
        trigger: element,
        start: "-50% top",
        toggleClass: { targets: ".sec-topAbout", className: "js-action" },
        once: true,
        // markers: true,
      });
    }
    // ▼ TOP ABOUT コンテンツフェードイン
    element = document.querySelector(".sec-topAbout .title-section");
    if (element) {
      gsap.from(element, {
        autoAlpha: 0,
        delay: 2,
        scrollTrigger: {
          trigger: ".topAbout_images",
          start: "-50% top",
          toggleActions: "play none none reverse",
          // markers: true,
        },
      });
    }
    element = document.querySelector(".sec-topAbout .topAbout_images");
    if (element) {
      gsap.from(element, {
        autoAlpha: 0,
        scrollTrigger: {
          trigger: ".topAbout_images",
          start: "-50% top",
          toggleActions: "play none none reverse",
          // markers: true,
        },
      });
    }
    element = document.querySelector(".sec-topAbout .topAbout_text *");
    if (element) {
      gsap.from(element, {
        autoAlpha: 0,
        delay: 2.5,
        scrollTrigger: {
          trigger: ".topAbout_images",
          start: "-50% top",
          toggleActions: "play none none reverse",
          // markers: true,
        },
      });
    }
    // ▼ TOP ACCESS マップ
    element = document.querySelector(".sec-topAccess .accessMap_pin");
    if (element) {
      gsap.from(element, {
        autoAlpha: 0,
        y: 30,
        duration: 1.5,
        scrollTrigger: {
          trigger: ".access",
          start: "bottom bottom",
          toggleActions: "play none none reverse",
          // markers: true,
        },
      });
    }
    element = document.querySelector(".sec-topAccess .accessMap_load");
    if (element) {
      gsap.from(element, {
        autoAlpha: 0,
        y: -30,
        duration: 1.5,
        scrollTrigger: {
          trigger: ".access",
          start: "bottom bottom",
          toggleActions: "play none none reverse",
          // markers: true,
        },
      });
    }
    element = document.querySelector(".sec-topAccess .accessMap_building");
    if (element) {
      gsap.from(element, {
        autoAlpha: 0,
        scrollTrigger: {
          trigger: ".access",
          start: "bottom bottom",
          toggleActions: "play none none reverse",
          // markers: true,
        },
      });
    }
    // ▼ サイドアンカーメニューが黒背景に重なったら反転用Class付与
    element = document.querySelectorAll(".sideAnchorMenu_item");
    if (element.length) {
      const backElement = document.querySelectorAll('[class^="sec-"], [class*=" sec-"]');
      const sectionElement = document.querySelectorAll("section");
      for (let i = 0; i < element.length; i++) {
        for (let index = 0; index < backElement.length; index++) {
          const item = backElement[index];
          if (item.classList.contains("-black")) {
            const triggerPos = element[i].getBoundingClientRect().top + element[i].clientHeight / 2;
            ScrollTrigger.create({
              trigger: item,
              start: "top top+=" + triggerPos,
              end: "bottom top+=" + triggerPos,
              toggleClass: { targets: element[i], className: "-white" },
              // markers: true,
            });
          }
        }
      }
      for (let i = 0; i < sectionElement.length; i++) {
        const sectionId = sectionElement[i].id;
        const sectionAnc = document.querySelector('.sideAnchorMenu_item a[href="#' + sectionId + '"]');
        // console.log(sectionAnc);
        ScrollTrigger.create({
          trigger: sectionElement[i],
          start: "top top+=1",
          end: "bottom top+=1",
          toggleClass: { targets: sectionAnc.parentNode, className: "-active" },
          // markers: true,
        });
        if (i === 0) {
          gsap.from(".sideAnchorMenu", {
            autoAlpha: 0,
            x: 20,
            scrollTrigger: {
              trigger: sectionElement[i],
              start: "top top+=1",
              toggleActions: "play none none reverse",
              // markers: true,
            },
          });
        }
        ScrollTrigger.create({
          trigger: sectionElement[i],
          start: "top-=300 top",
          end: "top+=100 top",
          snap: {
            snapTo: [0.75],
            delay: 0,
            // duration: { min: 0, max: 3 },
            duration: 1,
            ease: "none",
          },
          // markers: true,
        });
      }
    }
    // ▼ 銀座プレイスについてMV
    element = document.querySelector(".aboutHeader_img img");
    if (element) {
      gsap.to(element, {
        y: element.clientHeight / 4,
        ease: "none",
        scrollTrigger: {
          trigger: ".aboutHeader_img",
          start: "top top",
          scrub: true,
          toggleActions: "play none none reverse",
          // markers: true,
        },
      });
    }
  }
}
