import Gnav from "./utils/Gnav";
import ScrollAnim from "./utils/ScrollAnim";
import SwiperSetting from "./utils/Swiper";
import TopMv from "./utils/TopMv";
import Parallax from "./utils/Parallax";
import Popup from "./utils/Popup";

window.addEventListener("DOMContentLoaded", () => {
  new Gnav();
  new ScrollAnim();
  new SwiperSetting();
  new TopMv();
  new Parallax();
  new Popup();
});
