export default class {
  constructor(delayTime = 2000) {
    const flashingTime = 300;
    // const flashingDuration = 1500;
    const openTime = 7;

    const patterns = document.querySelectorAll("#patternSet > g:not(#pattern-open)");
    const openPatterns = document.querySelectorAll("#openPatterns > g");
    const mvNet = document.querySelectorAll("#img-mv-net svg");

    let randoms = [];
    const min = 0,
      max = openPatterns.length;

    for (let index = 0; index < patterns.length; index++) {
      const pattern = patterns[index];
      // pattern.classList.add("pattern-" + (index + 1));
      const rect = pattern.getBoundingClientRect();
      const isInView = 0 < rect.bottom && rect.top < window.innerHeight;
      // console.log({ isInView });
      if (isInView) {
        pattern.classList.add("inView");
      }
    }
    const inView = document.getElementsByClassName("inView");
    // console.log(patterns.length, inView.length);

    // console.log(max * openTime);

    function animOpen() {
      for (let i = min; i < max; i++) {
        while (true) {
          var tmp = intRandom(min, max);
          if (!randoms.includes(tmp)) {
            randoms.push(tmp);
            break;
          }
        }
      }

      function intRandom(min, max) {
        return Math.floor(Math.random() * (max - min)) + min;
      }
      // console.log(randoms);

      const openPattern = (item, i) => {
        setTimeout(() => {
          openPatterns[item].classList.add("open");
        }, i * openTime);
      };

      for (let i = 0; i < randoms.length; i++) {
        const item = randoms[i];
        openPattern(item, i);
      }
    }

    function animNetdel() {
      for (let index = 0; index < mvNet.length; index++) {
        const element = mvNet[index];
        element.classList.add("fadeout");
      }
      // mvNet.animate([{ opacity: 1 }, { opacity: 0 }], {
      //   duration: 1500,
      //   easing: "ease-in-out",
      //   fill: "forwards",
      // });
    }

    function animFlashing() {
      const flashing = () => {
        const random = Math.floor(Math.random() * inView.length);
        inView[random].classList.add("flash");
        setTimeout(() => {
          inView[random].classList.remove("flash");
        }, 2000);
      };
      setInterval(flashing, flashingTime);
    }

    async function mvAnim() {
      await animOpen();
      setTimeout(() => {
        animNetdel();
      }, max * openTime * 0.66 + 0);
      if (inView.length) {
        setTimeout(() => {
          animFlashing();
        }, max * openTime + 0);
      }
    }

    setTimeout(() => {
      const popup_st = document.querySelector("body").className;
      if (popup_st !== "-popupOpen") mvAnim();
    }, delayTime);

    // ▼ 昼と夜でMVの写真を変える
    let nowHour = new Date().getHours();

    // 18時から6時までは夜用の写真に差し替え
    if (18 <= nowHour || nowHour < 6) nightImg();

    function nightImg() {
      const mvImg = document.querySelectorAll("#mv-img picture");
      for (let index = 0; index < mvImg.length; index++) {
        const element = mvImg[index];
        // PC用写真の差し替え
        const imgElemnt = element.querySelector("img");
        let imgElemnt_src = imgElemnt.getAttribute("src");
        imgElemnt_src = imgElemnt_src.replace("-day-", "-night-");
        imgElemnt.src = imgElemnt_src;
        // SP用写真の差し替え
        const sourceElemnt = element.querySelector("source");
        let sourceElemnt_src = sourceElemnt.getAttribute("srcset");
        sourceElemnt_src = sourceElemnt_src.replace("-day-", "-night-");
        sourceElemnt.srcset = sourceElemnt_src;
      }
    }
  }
}
