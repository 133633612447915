import Cookies from "js-cookie";
import { lenis } from "./Parallax";
import topMv from "./TopMv";

export default class {
  constructor() {
    const testMode = false;
    let myCookie = Cookies.get("hide_popup");
    const popup_bg = document.querySelector(".popupAd_inner");
    const close = document.querySelector(".popupAd_close");

    if ((!myCookie && close) || testMode) {
      lenis.stop();
      document.body.classList.add("-popupOpen");
      popup_bg.addEventListener("click", popupClose);
      close.addEventListener("click", popupClose);
    }

    function popupClose(e) {
      if (e.target != e.currentTarget) return;
      lenis.start();
      document.body.classList.remove("-popupOpen");
      myCookie = 1;
      Cookies.set("hide_popup", myCookie, {
        expires: 1,
        path: "",
      });
      new topMv(2000);
    }
  }
}
