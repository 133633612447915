import Swiper, { Autoplay, EffectFade, Navigation, Pagination } from "swiper";

export default class {
  constructor() {
    // ▼ TOP MVスライダー
    const topMvObj = ".swiper.mv-img";
    const topMv = new Swiper(topMvObj, {
      modules: [Autoplay, EffectFade],
      loop: true,
      speed: 3000,
      autoplay: {
        delay: 3000,
        disableOnInteraction: true,
      },
      effect: "fade",
    });
    // パネルが開いてからautoplay開始
    if (document.querySelector(topMvObj) != null) {
      topMv.autoplay.stop();
      setTimeout(() => {
        topMv.autoplay.start();
      }, 9000);
    }

    // ▼ TOP NEWS & EVENT SP版用スライダー
    const topNewsEventObj = ".swiper.list-newsEvent";
    if (document.querySelectorAll(topNewsEventObj).length) {
      const options = {
        modules: [Autoplay, Navigation],
        loop: true,
        speed: 1500,
        autoplay: {
          delay: 1000,
          disableOnInteraction: false,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        spaceBetween: "9.524%",
        slidesPerView: "auto",
      };
      const breakPoint = 767;
      let topNewsEvent;
      if (window.innerWidth <= breakPoint) {
        topNewsEvent = new Swiper(".swiper.list-newsEvent", options);
      } else {
        topNewsEvent = undefined;
      }
      window.addEventListener(
        "resize",
        () => {
          if (window.innerWidth <= breakPoint) {
            if (topNewsEvent) return;
            topNewsEvent = new Swiper(".swiper.list-newsEvent", options);
          } else {
            if (!topNewsEvent) return;
            topNewsEvent.destroy();
            topNewsEvent = undefined;
          }
        },
        false
      );
      window.addEventListener("DOMContentLoaded", () => {}, false);
    }

    // ▼ トピックスバナースライダー
    const topicsBannerSlide = document.querySelectorAll(".swiper.topicsBanner .topicsBanner_item");
    if (topicsBannerSlide.length > 2) {
      const topicsBanner = new Swiper(".swiper.topicsBanner", {
        modules: [Autoplay, Navigation],
        loop: true,
        // loopedSlides: 3,
        initialSlide: 0,
        slidesPerView: "auto",
        // centeredSlides: true,
        speed: 1000,
        spaceBetween: 14,
        autoplay: {
          delay: 3000,
          disableOnInteraction: true,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        breakpoints: {
          // 769px以上の場合
          768: {
            spaceBetween: 24,
          },
        },
      });
    }

    // ▼ ショップ詳細ヘッダースライダー
    const shopHeaderObj = ".swiper.shopHeader_slider";
    const shopHeader = new Swiper(shopHeaderObj, {
      modules: [Autoplay, EffectFade, Pagination],
      loop: true,
      speed: 3000,
      effect: "fade",
      autoplay: {
        delay: 5000,
        disableOnInteraction: true,
      },
      pagination: {
        el: ".swiper.shopHeader_slider .swiper-pagination",
        clickable: true,
      },
      breakpoints: {
        // 768px以上の場合
        768: {
          slidesPerView: 1,
        },
      },
    });

    // ▼ NEWS詳細 SP版用スライダー
    const newsPhotoObj = ".swiper.newsPhoto";
    if (document.querySelectorAll(newsPhotoObj).length) {
      const options = {
        modules: [Autoplay, Navigation],
        loop: true,
        speed: 1500,
        autoplay: {
          delay: 3000,
          disableOnInteraction: false,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        spaceBetween: "9.524%",
        slidesPerView: "auto",
      };
      const breakPoint = 767;
      let newsPhoto;
      if (window.innerWidth <= breakPoint) {
        newsPhoto = new Swiper(newsPhotoObj, options);
      } else {
        newsPhoto = undefined;
      }
      window.addEventListener(
        "resize",
        () => {
          if (window.innerWidth <= breakPoint) {
            if (newsPhoto) return;
            newsPhoto = new Swiper(newsPhotoObj, options);
          } else {
            if (!newsPhoto) return;
            newsPhoto.destroy();
            newsPhoto = undefined;
          }
        },
        false
      );
      window.addEventListener("DOMContentLoaded", () => {}, false);
    }
  }
}
